// src/routes/index.tsx
import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Spinner from "@components/Spinner";
import PrivateRoute from "@components/PrivateRoute";
import PublicRoute from "@components/PublicRoute";
import PageTransition from "@components/PageTransition";
import { lazy } from "react";

const Login = lazy(() => import("@views/Auth/Login"));
const Register = lazy(() => import("@views/Auth/Register"));
const Dashboard = lazy(() => import("@views/Dashboard"));
const VerifyEmail = lazy(() => import("@views/Auth/VerifyEmail"));
const Logout = lazy(() => import("@views/Auth/Logout"));
const TokenVerificationEmail = lazy(
  () => import("@views/Auth/TokenVerificationEmail")
);

const AppRoutes: React.FC = () => {
  return (
    <PageTransition>
      <Suspense fallback={<Spinner />}>
        <Routes>
          {/* Rutas públicas */}
          <Route path="/login" element={<PublicRoute element={<Login />} />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/register"
            element={<PublicRoute element={<Register />} />}
          />
          <Route
            path="/verify-email"
            element={<PublicRoute element={<VerifyEmail />} />}
          />
          <Route
            path="/token-verification-email"
            element={<PublicRoute element={<TokenVerificationEmail />} />}
          />

          {/* Rutas privadas */}
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<Dashboard />} />}
          />
          {/* Ruta por defecto */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Suspense>
    </PageTransition>
  );
};

export default AppRoutes;
