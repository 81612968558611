import React from "react";
import { Spinner as BootstrapSpinner } from "reactstrap";

const Spinner: React.FC = () => (
  <div className="d-flex justify-content-center align-items-center vh-100">
    <BootstrapSpinner color="primary" />
  </div>
);

export default Spinner;
