import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";

// Importar estilos de dependencias
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// Importar estilos personalizados
import "@assets/css/typography.css";
import "@assets/css/custom.css";
import "@assets/css/responsive.css";
import "@assets/css/helper.css";

// Importar estilos SCSS personalizados
import "@assets/scss/style.scss";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
