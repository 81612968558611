// src/services/api.ts
import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from "axios";
import { useAuthStore } from "@/stores/auth";
import { ApiResponse, ApiError, ApiErrorResponse } from "./types";

// Extended interface for custom _retry property
interface CustomInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

class ApiClient {
  private client: AxiosInstance;
  private isRefreshing = false;
  private refreshSubscribers: ((token: string) => void)[] = [];

  constructor() {
    this.client = axios.create({
      baseURL:
        import.meta.env.VITE_API_URL || "https://dev-api-partner.809market.com",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.setupInterceptors();
  }

  private setupInterceptors() {
    // Request interceptor
    this.client.interceptors.request.use(
      (config) => {
        const { accessToken } = useAuthStore.getState();
        if (accessToken && config.headers) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Response interceptor
    this.client.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<ApiErrorResponse>) => {
        const originalRequest =
          error.config as CustomInternalAxiosRequestConfig;

        if (!originalRequest) {
          return Promise.reject(error);
        }

        // Handle 401 errors and token refresh
        if (
          error.response?.status === 401 &&
          !originalRequest._retry &&
          !originalRequest.url?.includes("/auth/login") &&
          !originalRequest.url?.includes("/auth/refresh")
        ) {
          if (this.isRefreshing) {
            try {
              const token = await new Promise<string>((resolve) => {
                this.refreshSubscribers.push((token: string) => {
                  resolve(token);
                });
              });
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              return this.client(originalRequest);
            } catch (err) {
              return Promise.reject(err);
            }
          }

          originalRequest._retry = true;
          this.isRefreshing = true;

          try {
            const { refreshToken } = useAuthStore.getState();
            if (!refreshToken) {
              throw new Error("No hay refresh token disponible");
            }

            const response = await this.client.post<
              ApiResponse<{
                access_token: string;
                refresh_token: string;
              }>
            >("/auth/refresh", {
              refresh_token: refreshToken,
            });

            if (response.data.status === "success") {
              const { access_token, refresh_token } = response.data.data;

              useAuthStore.setState({
                accessToken: access_token,
                refreshToken: refresh_token,
                isAuthenticated: true,
              });

              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${access_token}`;

              // Execute all pending requests
              this.refreshSubscribers.forEach((callback) =>
                callback(access_token)
              );
              this.refreshSubscribers = [];

              return this.client(originalRequest);
            } else {
              throw new ApiError(
                response.data.message,
                response.data.error,
                response.status
              );
            }
          } catch (err) {
            const { logout } = useAuthStore.getState();
            logout();
            return Promise.reject(err);
          } finally {
            this.isRefreshing = false;
          }
        }

        // Handle other API errors
        if (error.response?.data) {
          throw new ApiError(
            error.response.data.message,
            error.response.data.error,
            error.response.status
          );
        }

        // Handle network errors or other issues
        throw new Error(error.message || "Error en la solicitud");
      }
    );
  }

  async request<T = any>(config: any): Promise<T> {
    try {
      const response: AxiosResponse<ApiResponse<T>> = await this.client(config);

      if (response.data.status === "success") {
        return response.data.data;
      }

      throw new ApiError(
        response.data.message,
        response.data.error,
        response.status
      );
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      throw new Error("Error en la solicitud");
    }
  }

  async get<T = any>(url: string, config = {}): Promise<T> {
    return this.request<T>({ ...config, method: "GET", url });
  }

  async post<T = any>(url: string, data = {}, config = {}): Promise<T> {
    return this.request<T>({ ...config, method: "POST", url, data });
  }

  async put<T = any>(url: string, data = {}, config = {}): Promise<T> {
    return this.request<T>({ ...config, method: "PUT", url, data });
  }

  async delete<T = any>(url: string, config = {}): Promise<T> {
    return this.request<T>({ ...config, method: "DELETE", url });
  }
}

export default new ApiClient();
