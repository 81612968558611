export interface ApiSuccessResponse<T = any> {
  status: "success";
  message: string;
  data: T;
}

export interface ApiErrorDetails {
  code: string;
  type: string;
  details: any[];
}

export interface ApiErrorResponse {
  status: "error";
  message: string;
  error: ApiErrorDetails;
}

export type ApiResponse<T = any> = ApiSuccessResponse<T> | ApiErrorResponse;

export class ApiError extends Error {
  code: string;
  type: string;
  details: any[];
  status?: number;

  constructor(message: string, error: ApiErrorDetails, status?: number) {
    super(message);
    this.name = "ApiError";
    this.code = error.code;
    this.type = error.type;
    this.details = error.details;
    this.status = status;
  }
}
