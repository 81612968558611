// src/stores/auth.ts

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { loginService } from "@/services/auth/login";
import { LoginRequest } from "@/services/auth/login/types";

interface User {
  user_id: number;
  email: string;
  roles: string;
}

interface AuthState {
  user: User | null;
  accessToken: string;
  refreshToken: string;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      accessToken: "",
      refreshToken: "",
      isAuthenticated: false,

      login: async (email, password) => {
        const data = await loginService({ email, password } as LoginRequest);
        const {
          access_token,
          refresh_token,
          user_id,
          email: userEmail,
          roles,
        } = data;

        set({
          user: { user_id, email: userEmail, roles },
          accessToken: access_token,
          refreshToken: refresh_token,
          isAuthenticated: true,
        });
      },

      logout: () => {
        set({
          user: null,
          accessToken: "",
          refreshToken: "",
          isAuthenticated: false,
        });
      },
    }),
    {
      name: "auth-storage",
      partialize: (state) => ({
        user: state.user,
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        isAuthenticated: state.isAuthenticated,
      }),
    }
  )
);
