// src/components/PageTransition.tsx
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./styles.css";

interface PageTransitionProps {
  children: React.ReactNode;
}

const PageTransition: React.FC<PageTransitionProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const element = document.querySelector(".page-transition");
    if (element) {
      element.classList.remove("animate__animated", "animate__fadeIn");
      void (element as HTMLElement).offsetWidth; // Trigger reflow
      element.classList.add("animate__animated", "animate__fadeIn");
    }
  }, [location.pathname]);

  return (
    <div className="page-transition animate__animated animate__fadeIn">
      {children}
    </div>
  );
};

export default PageTransition;
