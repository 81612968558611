// src/components/Notification.tsx
import React, { useCallback, useEffect, useState } from "react";
import { useNotificationStore } from "@stores/notification";
import { Alert } from "reactstrap";

// Define the notification type as union of literal strings
type NotificationType = "success" | "error" | "info" | "warning";

// Define the notification object interface
interface Notification {
  id: number;
  type: NotificationType;
  message: string;
}

interface NotificationItemProps {
  notification: Notification;
}

const Notification: React.FC = () => {
  const { notifications } = useNotificationStore();

  return (
    <div
      className="position-fixed top-0 end-0 p-3"
      style={{ zIndex: 1050, maxWidth: "400px" }}
    >
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}
    </div>
  );
};

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
}) => {
  const { removeNotification } = useNotificationStore();
  const [exiting, setExiting] = useState(false);

  const handleClose = useCallback(() => {
    setExiting(true);
    setTimeout(() => {
      removeNotification(notification.id);
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [handleClose]); // Added handleClose to dependency array

  const animationClass = exiting
    ? "animate__fadeOutRight"
    : "animate__fadeInRight";
  const animationDuration = "200ms";

  return (
    <Alert
      color={getAlertColor(notification.type)}
      toggle={handleClose}
      className={`d-flex align-items-center mb-3 animate__animated ${animationClass}`}
      isOpen={true}
      fade={false}
      style={{ "--animate-duration": animationDuration } as React.CSSProperties}
    >
      <div className="me-3">
        <i className={`fa ${getIconClass(notification.type)} fa-2x`}></i>
      </div>
      <div>
        <h6 className="alert-heading mb-1">
          {getAlertTitle(notification.type)}
        </h6>
        <p className="mb-0">{notification.message}</p>
      </div>
    </Alert>
  );
};

function getAlertColor(type: NotificationType): string {
  switch (type) {
    case "success":
      return "success";
    case "error":
      return "danger";
    case "info":
      return "info";
    case "warning":
      return "warning";
    default:
      return "primary";
  }
}

function getAlertTitle(type: NotificationType): string {
  switch (type) {
    case "success":
      return "¡Éxito!";
    case "error":
      return "¡Error!";
    case "info":
      return "Información";
    case "warning":
      return "Advertencia";
    default:
      return "";
  }
}

function getIconClass(type: NotificationType): string {
  switch (type) {
    case "success":
      return "fa-check-circle text-success";
    case "error":
      return "fa-times-circle text-danger";
    case "info":
      return "fa-info-circle text-info";
    case "warning":
      return "fa-exclamation-triangle text-warning";
    default:
      return "fa-info-circle";
  }
}

export default Notification;
